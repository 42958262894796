import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CatCard from './cat-card/CatCard';
import { featuredCategoriesData } from '../../data/categoriesData';
import { Link, useParams } from 'react-router-dom';
import { fetchCategoriesAction } from '../../store/slices/categories/categoriesSlice';
import LoadingComponent from '../shared/LoadingComponent';

const FeaturedCategories = () => {
  const {
    categories: { categories },
    loading,
    error
  } = useSelector(state => state?.categories);

  return (
    <div className='w-full'>
      <div className='relative mx-auto w-full max-w-[1200px]'>
        <Link
          to='/all-categories'
          className='pri_text absolute right-2 top-12 hidden lrg:flex'
        >
          All Categories
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='mt-1 size-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3'
            />
          </svg>
        </Link>
        <div className='grid text-center'>
          <h1 className='autoShow sec_text text-6xl sm:text-7xl'>
            <span className='font-thin'>FEATURED</span> CATEGORIES
          </h1>
          <div className='my-10 grid w-full max-w-[1180px] gap-4 pl-4 md:grid-cols-2 md:p-4 lg:grid-cols-4'>
            {loading ? (
              <LoadingComponent />
            ) : (
              <CatCard categories={categories} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedCategories;
