import React from 'react';
import Layout from '../components/Layout/Layout';
import ProductsFilters from '../components/product/ProductsFilters';

const ProductsFiltersPage = () => {
  return (
    <Layout>
      <ProductsFilters />
    </Layout>
  );
};

export default ProductsFiltersPage;
