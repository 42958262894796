import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

import { fetchBrandsAction } from '../../../store/slices/categories/brandsSlice';
import { fetchCategoriesAction } from '../../../store/slices/categories/categoriesSlice';
import { fetchColorsAction } from '../../../store/slices/categories/colorsSlice';
import {
  createProductAction,
  fetchProductAction,
  updateProductAction
} from '../../../store/slices/productSlice';

import ErrorMsg from '../../shared/ErrorMsg';
import LoadingComponent from '../../shared/LoadingComponent';

//animated components for react-select
const animatedComponents = makeAnimated();

export default function ProductUpdate() {
  const { categories } = useSelector(state => state?.categories?.categories);
  const { product, isUpdated, loading, error } = useSelector(
    state => state?.products
  );
  const {
    brands: { brands }
  } = useSelector(state => state?.brands);

  const {
    colors: { colors }
  } = useSelector(state => state?.colors);

  const [sizeOption, setSizeOption] = useState([]);
  const [colorsOption, setColorsOption] = useState([]);

  //files
  const [files, setFiles] = useState([]);
  const [fileErrs, setFileErrs] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  //fetch single product
  useEffect(() => {
    dispatch(fetchProductAction(id));
  }, [id, dispatch]);

  //categories
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);

  //brands
  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);

  //colors
  useEffect(() => {
    dispatch(fetchColorsAction());
  }, [dispatch]);

  //---form data---
  const [formData, setFormData] = useState({
    name: product?.product?.name,
    description: product?.product?.description,
    category: '',
    sizes: '',
    brand: '',
    colors: '',
    price: product?.product?.price,
    totalQty: product?.product?.totalQty
  });

  const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

  //converted sizes
  const sizeOptionsConverted = sizes?.map(size => {
    return {
      value: size,
      label: size
    };
  });

  //Sizes
  const handleSizeChange = sizes => {
    setSizeOption(sizes);
  };

  const handleColorChange = colors => {
    setColorsOption(colors);
  };

  //converted colors
  const colorsConverted = colors?.map(color => {
    return {
      value: color?.name,
      label: color?.name
    };
  });

  //file handle change
  const fileHandleChange = event => {
    const newFiles = Array.from(event.target.files);
    //validation
    const newErrs = [];
    newFiles.forEach(file => {
      if (file?.size > 10000000) {
        newErrs.push(`${file?.name} is too large`);
      }
      if (!file?.type?.startsWith('image/')) {
        newErrs.push(`${file?.name} is not an image`);
      }
    });
    setFiles(newFiles);
    setFileErrs(newErrs);
  };

  //onChange
  const handleOnChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onSubmit
  const handleOnSubmit = e => {
    e.preventDefault();
    //dispatch
    dispatch(
      updateProductAction({
        ...formData,
        id,
        colors: colorsOption?.map(color => color.label),
        sizes: sizeOption?.map(size => size?.label)
      })
    );

    if (isUpdated) {
      toast.success('Product updated successfully');
    }

    //reset form data
    setFormData({
      name: '',
      description: '',
      category: '',
      sizes: '',
      brand: '',
      colors: '',
      images: '',
      price: '',
      totalQty: ''
    });
  };

  return (
    <>
      {error && <ErrorMsg error={error} />}
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Update Product
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            <p className='font-medium text-[#5f98b7] hover:text-[#5f98b7]'>
              Manage Products
            </p>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleOnSubmit}>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Product Name
                </label>
                <div className='mt-1'>
                  <input
                    name='name'
                    value={formData?.name}
                    onChange={handleOnChange}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              {/* size option */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Size
                </label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name='sizes'
                  options={sizeOptionsConverted}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable={true}
                  isLoading={false}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={item => handleSizeChange(item)}
                />
              </div>
              {/* Select category */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Category
                </label>
                <select
                  name='category'
                  value={formData.category}
                  onChange={handleOnChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  defaultValue='Canada'
                >
                  <option>-- Select Category --</option>
                  {categories?.map(category => (
                    <option key={category?._id} value={category?.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Select Brand */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Brand
                </label>
                <select
                  name='brand'
                  value={formData.brand}
                  onChange={handleOnChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  defaultValue='Canada'
                >
                  <option>-- Select Brand --</option>
                  {brands?.map(brand => (
                    <option key={brand?._id} value={brand?.name}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select Color */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Color
                </label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name='colors'
                  options={colorsConverted}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable={true}
                  isLoading={false}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={e => handleColorChange(e)}
                />
              </div>

              {/* price */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Price
                </label>
                <div className='mt-1'>
                  <input
                    name='price'
                    value={formData.price}
                    onChange={handleOnChange}
                    type='number'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>

              {/* Quantity */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Total Quantity
                </label>
                <div className='mt-1'>
                  <input
                    name='totalQty'
                    value={formData.totalQty}
                    onChange={handleOnChange}
                    type='number'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              {/* description */}
              <div>
                <label
                  htmlFor='comment'
                  className='block text-sm font-medium text-gray-700'
                >
                  Add Product Description
                </label>
                <div className='mt-1'>
                  <textarea
                    rows={4}
                    name='description'
                    value={formData.description}
                    onChange={handleOnChange}
                    className='block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Update Product
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
