import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';

import baseURL from '../../utils/baseURL';

const MobileMenu = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  categoriesToDisplay,
  isLoggedIn,
  logoutHandler
}) => {
  let cartItemsFromLocalStorage = [];
  return (
    <>
      <div className='fixed left-[10px] top-[50px] z-[1001] md:hidden'>
        <div
          className='flex cursor-pointer flex-col gap-[4.5px]'
          onClick={() => setMobileMenuOpen(prev => !prev)}
        >
          <div
            className={`h-1 w-6 rounded-sm bg-[#a7977e] ${
              mobileMenuOpen ? 'rotate-45' : ''
            } origin-left duration-500 ease-in-out`}
          />
          <div
            className={`h-1 w-6 rounded-sm bg-[#a7977e] ${
              mobileMenuOpen ? 'opacity-0' : ''
            } duration-500 ease-in-out`}
          />
          <div
            className={`h-1 w-6 rounded-sm bg-[#a7977e] ${
              mobileMenuOpen ? '-rotate-45' : ''
            } origin-left duration-500 ease-in-out`}
          />
        </div>
      </div>
      {mobileMenuOpen && (
        <div className='1-10 pri_text absolute left-0 top-20 z-[100] flex h-[calc(100vh-96px)] w-full flex-col items-center justify-center gap-8 bg-black text-xl font-medium'>
          {!isLoggedIn && (
            <>
              <Link to='/login'>Sign In</Link>
              <Link to='/register'>Sign Up</Link>
            </>
          )}
          {categoriesToDisplay?.length <= 0 ? (
            <div className='flex flex-col text-center'>
              <Link to={`/products?category=clothing`} className='my-4'>
                Clothing
              </Link>
              <Link to={`/products?category=men`} className='my-4'>
                Men
              </Link>
              <Link to={`/products?category=women`} className='my-4'>
                Women
              </Link>
            </div>
          ) : (
            categoriesToDisplay?.map(category => {
              return (
                <>
                  <Link
                    key={category?._id}
                    to={`/products-filters?category=${category?.name}`}
                  >
                    {category?.name}
                  </Link>
                </>
              );
            })
          )}
          {/* Logout User */}
          <button onClick={logoutHandler}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='mb-8 size-6 text-[#a7977e]'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9'
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
