const SuccessMsg = ({ success }) => {
  console.log(success.message);

  // Swal.fire({
  //   icon: "success",
  //   title: "Good job!",
  //   text: message,
  // });
  <h1>Nice</h1>;
};

export default SuccessMsg;
