import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { resetErrAction } from '../../store/slices/globalActions/globalActions';

const ErrorMsg = ({ error }) => {
  const dispatch = useDispatch();

  if (error) {
    toast.error(error.message);
  }
  dispatch(resetErrAction());
  <h1>Error Occurred</h1>;
};

export default ErrorMsg;
