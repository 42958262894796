import React from 'react';
import RegisterForm from '../components/user/forms/RegisterForm';
import Layout from '../components/Layout/Layout';

const Register = () => {
  return (
    <Layout>
      <RegisterForm />
    </Layout>
  );
};

export default Register;
