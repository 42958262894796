import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { createReviewAction } from '../store/slices/reviewSlice';
import ErrorMsg from './shared/ErrorMsg';
import LoadingComponent from './shared/LoadingComponent';
import { toast } from 'react-toastify';

export default function AddReview() {
  const { loading, error, isAdded } = useSelector(state => state?.review);

  const [formData, setFormData] = useState({
    rating: '',
    message: ''
  });

  const dispatch = useDispatch();
  const { id } = useParams();

  //onChange
  const handleOnChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onSubmit
  const handleOnSubmit = e => {
    e.preventDefault();
    dispatch(
      createReviewAction({
        id,
        message: formData.message,
        rating: formData.rating
      })
    );
    if (isAdded) {
      toast.success('Thank you for your feedback');
    }
  };

  return (
    <>
      {error && <ErrorMsg error={error} />}
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Add Your Review
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            <p className='font-medium text-[#5f98b7] hover:text-[#5f98b790]'>
              You are reviewing:{' '}
              <span className='text-gray-900'>Product Name</span>
            </p>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleOnSubmit}>
              <div>
                <label
                  htmlFor='location'
                  className='block text-sm font-medium text-gray-700'
                >
                  Rating
                </label>
                <select
                  value={formData.rating}
                  onChange={handleOnChange}
                  name='rating'
                  className='mt-1 block w-full rounded-md border-2 border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#5f98b790] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  defaultValue='Canada'
                >
                  {/* review rating */}

                  <option value='1'>1</option>

                  <option value='2'>2</option>

                  <option value='3'>3</option>

                  <option value='4'>4</option>

                  <option value='5'>5 </option>
                </select>
              </div>

              {/* description */}
              <div>
                <label
                  htmlFor='comment'
                  className='block text-sm font-medium text-gray-700'
                >
                  Message
                </label>
                <div className='mt-1'>
                  <textarea
                    rows={4}
                    name='message'
                    value={formData.message}
                    onChange={handleOnChange}
                    className='block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Add New Review
                  </button>
                )}
              </div>

              <div>
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  I have Changed my mind
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
