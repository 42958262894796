import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, page }) => {
  return (
    <>
      <Header />
      {children}
      {page === 'admin' ? null : (
        <>
          <Footer page={page} />
        </>
      )}
    </>
  );
};

export default Layout;
