import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserProfileAction,
  updateUserShippingAddressAction
} from '../../../store/slices/userSlice';
import ErrorMsg from '../../shared/ErrorMsg';
import LoadingComponent from '../../shared/LoadingComponent';
import SuccessMsg from '../../shared/SuccessMsg';

const AddShippingAddress = () => {
  const { loading, error, userAuth } = useSelector(state => state?.users);

  // const user = profile?.user;
  const user = userAuth.userInfo.user;

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    country: 'United States',
    state: '',
    zipCode: '',
    phone: ''
  });

  const dispatch = useDispatch();

  //user profile
  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  //onchange
  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onsubmit
  const onSubmit = e => {
    console.log(formData);
    e.preventDefault();
    dispatch(updateUserShippingAddressAction(formData));
  };

  return (
    <>
      {error && <ErrorMsg error={error} />}
      {/* shipping details */}
      {user?.hasShippingAddress ? (
        <div className='mt-6'>
          <h3 className='text-lg font-medium text-gray-900'>
            Shipping details
          </h3>

          <p className='mt-1 text-sm text-gray-500'>
            Double check your information.
          </p>
          <div>
            <p className='mt-1 text-sm text-gray-500'>
              First Name : {user?.shippingAddress?.firstName}
            </p>
            <p className='mt-1 text-sm text-gray-500'>
              Last Name : {user?.shippingAddress?.lastName}
            </p>
            <p className='mt-1 text-sm text-gray-500'>
              Address : {user?.shippingAddress?.address}
            </p>
            <p className='mt-1 text-sm text-gray-500'>
              City : {user?.shippingAddress?.city}
            </p>
            <p className='mt-1 text-sm text-gray-500'>
              Country : {user?.shippingAddress?.country}
            </p>
            <p className='mt-1 text-sm text-gray-500'>
              phone : {user?.shippingAddress?.phone}
            </p>
          </div>
        </div>
      ) : (
        <form
          onSubmit={onSubmit}
          className='mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4'
        >
          <div>
            <label
              htmlFor='first-name'
              className='block text-sm font-medium text-gray-700'
            >
              First name
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='firstName'
                onChange={onChange}
                value={formData.firstName}
                autoComplete='given-name'
                className='block w-full rounded-md border-gray-400 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='last-name'
              className='block text-sm font-medium text-gray-700'
            >
              Last name
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='lastName'
                onChange={onChange}
                value={formData.lastName}
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div className='sm:col-span-2'>
            <label
              htmlFor='address'
              className='block text-sm font-medium text-gray-700'
            >
              Address
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='address'
                onChange={onChange}
                value={formData.address}
                autoComplete='street-address'
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='city'
              className='block text-sm font-medium text-gray-700'
            >
              City
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='city'
                onChange={onChange}
                value={formData.city}
                autoComplete='address-level2'
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='country'
              className='block text-sm font-medium text-gray-700'
            >
              Country
            </label>
            <div className='mt-1'>
              <select
                id='country'
                name='country'
                autoComplete='country'
                value={formData.country}
                onChange={onChange}
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              >
                <option value='USA'>United States</option>
                <option value='CAN'>Canada</option>
                <option value='MEX'>Mexico</option>
              </select>
            </div>
          </div>

          <div>
            <label
              htmlFor='state'
              className='block text-sm font-medium text-gray-700'
            >
              State / Province
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='state'
                onChange={onChange}
                value={formData.state}
                autoComplete='address-level1'
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div>
            <label
              htmlFor='zip-code'
              className='block text-sm font-medium text-gray-700'
            >
              Postal code
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='zipCode'
                onChange={onChange}
                value={formData.zipCode}
                autoComplete='-code'
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>

          <div className='sm:col-span-2'>
            <label
              htmlFor='phone'
              className='block text-sm font-medium text-gray-700'
            >
              Phone
            </label>
            <div className='mt-1'>
              <input
                type='text'
                name='phone'
                id='phone'
                onChange={onChange}
                value={formData.phone}
                autoComplete='tel'
                className='block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
              />
            </div>
          </div>
          {loading ? (
            <LoadingComponent />
          ) : (
            <button
              type='submit'
              className='w-full rounded-md border border-transparent bg-[#5f98b7] px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2 focus:ring-offset-gray-50'
            >
              Add Shipping Address
            </button>
          )}
        </form>
      )}
    </>
  );
};

export default AddShippingAddress;
