import React from 'react';
import ProductCard from './product-card/ProductCard';

const Products = ({ products, page }) => {
  return (
    <>
      {/* <div className='mx-auto my-10 grid w-full max-w-[1180px] grid-cols-1 justify-between gap-4 bg-red-400 pl-4 md:grid-cols-2 md:p-4 lg:grid-cols-4'> */}
      <div
        className={
          page === 'product-filters'
            ? 'mx-auto my-10 grid w-full max-w-[1180px] grid-cols-1 justify-between gap-4 pl-4 md:grid-cols-2 md:p-4 lg:grid-cols-4'
            : 'grid w-full gap-4 md:grid-cols-2 lg:min-w-[900px] lg:grid-cols-3'
        }
      >
        {products?.map(product => (
          <ProductCard product={product} key={product.id} />
        ))}
      </div>
    </>
  );
};

export default Products;
