import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CatCard = ({ categories }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const allCategoriesData = categories ? [...categories] : [];
    const sortedData = allCategoriesData?.filter(a => a?.featured === true);
    const firstFour = sortedData && sortedData?.slice(0, 3);
    setData(firstFour);
  }, []);

  return (
    <>
      {data.map(cat => (
        <Link
          to={`/products-filters?category=${cat?.name}`}
          key={cat.id}
          className='h-[260px] w-[260px]'
        >
          <img
            src={cat?.image}
            className='h-[80%] w-full object-cover'
            alt=''
          />
          <div className='pri_text flex h-[20%] w-full flex-col text-center'>
            <div className='mt-2 text-2xl font-light capitalize'>
              {cat?.name}
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default CatCard;
