import { configureStore } from '@reduxjs/toolkit';

import usersReducer from './slices/userSlice';
import productsReducer from './slices/productSlice';
import categoryReducer from './slices/categories/categoriesSlice';
import brandsReducer from './slices/categories/brandsSlice';
import colorsReducer from './slices/categories/colorsSlice';
import cartReducer from './slices/cartSlice';
import ordersReducer from './slices/orderSlice';
import couponsReducer from './slices/couponSlice';
import reviewsReducer from './slices/reviewSlice';

// store v2
const store = configureStore({
  reducer: {
    users: usersReducer,
    products: productsReducer,
    categories: categoryReducer,
    brands: brandsReducer,
    colors: colorsReducer,
    cart: cartReducer,
    orders: ordersReducer,
    coupons: couponsReducer,
    review: reviewsReducer
  }
});

export default store;
