import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrdersStatsAction } from '../../../store/slices/orderSlice';

export default function OrdersStats() {
  const { stats, loading, error } = useSelector(state => state?.orders);

  console.log(stats);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(OrdersStatsAction());
  }, []);

  const obj = stats?.orders;
  const statistics = obj ? Object.values(obj[0]) : [];

  return (
    <div>
      <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4'>
        {/* Today's sales */}
        <div className='relative overflow-hidden rounded-lg border-[1px] border-gray-300 bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'>
          <dt>
            <div className='absolute rounded-md bg-[#a7977e] p-3'>
              <svg
                className='h-6 w-6 text-white'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4'
                ></path>
              </svg>
            </div>
            <p className='ml-16 truncate text-sm font-medium text-black'>
              Today's Sales
            </p>
          </dt>
          <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
            <p className='text-2xl font-semibold text-gray-900'>
              {stats?.saleToday?.length <= 0 ? (
                '$0.00'
              ) : (
                <div>{stats?.saleToday?.totalSales}?</div>
              )}
            </p>

            <div className='absolute inset-x-0 bottom-0 bg-[#a7977e] px-4 py-4 sm:px-6'>
              <div className='text-sm'></div>
            </div>
          </dd>
        </div>
        {/* stat 1 */}
        <div className='relative overflow-hidden rounded-lg border-[1px] border-gray-300 bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'>
          <dt>
            <div className='absolute rounded-md bg-[#a7977e] p-3'>
              <svg
                className='h-6 w-6 text-white'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4'
                ></path>
              </svg>
            </div>
            <p className='ml-16 truncate text-sm font-medium text-black'>
              Minimum Order
            </p>
          </dt>
          <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
            <p className='text-2xl font-semibold text-gray-900'>
              ${statistics[1]}
            </p>

            <div className='absolute inset-x-0 bottom-0 bg-[#a7977e] px-4 py-4 sm:px-6'>
              <div className='text-sm'></div>
            </div>
          </dd>
        </div>
        {/* stat 2 */}
        <div className='relative overflow-hidden rounded-lg border-[1px] border-gray-300 bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'>
          <dt>
            <div className='absolute rounded-md bg-[#a7977e] p-3'>
              <svg
                className='h-6 w-6 text-white'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4'
                ></path>
              </svg>
            </div>
            <p className='ml-16 truncate text-sm font-medium text-gray-900'>
              Maximum Oder
            </p>
          </dt>
          <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
            <p className='text-2xl font-semibold text-gray-900'>
              ${statistics[3]}
            </p>

            <div className='absolute inset-x-0 bottom-0 bg-[#a7977e] px-4 py-4 sm:px-6'>
              <div className='text-sm'></div>
            </div>
          </dd>
        </div>
        {/* stat 3 */}
        <div className='relative overflow-hidden rounded-lg border-[1px] border-gray-300 bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6'>
          <dt>
            <div className='absolute rounded-md bg-[#a7977e] p-3'>
              <svg
                className='h-6 w-6 text-white'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4'
                ></path>
              </svg>
            </div>
            <p className='ml-16 truncate text-sm font-medium text-gray-900'>
              Total Sales
            </p>
          </dt>
          <dd className='ml-16 flex items-baseline pb-6 sm:pb-7'>
            <p className='text-2xl font-semibold text-gray-900'>
              {' '}
              ${statistics[2]}
            </p>

            <div className='absolute inset-x-0 bottom-0 bg-[#a7977e] px-4 py-4 sm:px-6'>
              <div className='text-sm'></div>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}
