import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import LoadingComponent from '../../shared/LoadingComponent';
import ErrorMsg from '../../shared/ErrorMsg';
import {
  fetchCouponAction,
  updateCouponAction
} from '../../../store/slices/couponSlice';

export default function UpdateCoupon() {
  const { coupon, loading, error, isUpdated } = useSelector(
    state => state?.coupons
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //get coupon from url
  const { code } = useParams();
  const dispatch = useDispatch();

  //---Fetch coupon ---
  useEffect(() => {
    dispatch(fetchCouponAction(code));
  }, [code, dispatch]);

  //---handle form data---
  const [formData, setFormData] = useState({
    code: coupon?.coupon?.code,
    discount: coupon?.coupon?.discount
  });

  //onHandleChange---
  const onHandleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //onHandleSubmit---
  const onHandleSubmit = e => {
    e.preventDefault();
    dispatch(
      updateCouponAction({
        id: coupon?.coupon?._id,
        code: formData?.code,
        discount: formData?.discount,
        startDate,
        endDate
      })
    );

    if (isUpdated) {
      toast.success('Coupon updated successfully');
    }
    //reset
    setFormData({
      code: '',
      discount: ''
    });
  };

  return (
    <>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Update Coupon
        </h2>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
          {error ? (
            <ErrorMsg message={error?.message} />
          ) : (
            <form className='space-y-6' onSubmit={onHandleSubmit}>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  {/* name */}
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='code'
                    value={formData?.code}
                    onChange={onHandleChange}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  {/* discount */}
                  Discount (in %)
                </label>
                <div className='mt-1'>
                  <input
                    name='discount'
                    value={formData?.discount}
                    onChange={onHandleChange}
                    type='number'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              {/* start date */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Start Date
                </label>
                <div className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'>
                  <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                  />
                </div>
              </div>

              {/* end date */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  End Date
                </label>
                <div className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'>
                  <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                  />
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Update Coupon
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
