import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='h-content pri_dark_bg pri_text relative flex flex-col justify-center pb-6 pt-10'>
      <div className='mx-auto grid h-[90%] w-full max-w-[1200px] sm:grid-cols-3 md:ml-0 xl:ml-[16%] xxl:ml-[24%]'>
        <ul className='m-4 mt-5 p-2 md:m-1'>
          <li className='mb-2 text-xl font-thin'>Our Blog</li>
          <li className='mb-2 text-xl font-thin'>About Us</li>
          <li className='mb-2 text-xl font-thin'>Contact Us</li>
        </ul>
        <ul className='m-4 mt-5 p-2 md:m-1'>
          <li className='mb-2 text-xl font-thin'>Social App</li>
          <Link to='https://www.nxxtstore.com'>
            <li className='mb-2 text-xl font-thin'>Nxxtstore</li>
          </Link>

          <li className='mb-2 text-xl font-thin'>item 3</li>
        </ul>
        <ul className='m-4 mt-5 p-2 md:m-1'>
          <li className='mb-2 text-xl font-thin'>item 1</li>
          <li className='mb-2 text-xl font-thin'>item 2</li>
          <li className='mb-2 text-xl font-thin'>item 3</li>
        </ul>
      </div>
      <div className='mx-auto mt-10'>
        Copyright © 2024,{' '}
        <Link to='https://www.nxxtstore.com'>Nxxtstore Inc.</Link>
      </div>
    </div>
  );
};

export default Footer;
