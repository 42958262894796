import { Fragment, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';

import { IoMdClose } from 'react-icons/io';
import { HiMiniBars3 } from 'react-icons/hi2';
import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const ordersLinks = [
  {
    name: 'Dashboard',
    href: '',
    icon: () => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='m-1 h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25'
        />
      </svg>
    ),
    current: true
  },
  {
    name: 'Customers',
    href: 'customers',
    icon: () => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='m-1 h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z'
        />
      </svg>
    )
  }
];

const productsLinks = [
  {
    name: 'Add Product',
    href: 'add-product',
    icon: () => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='m-1 h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125'
        />
      </svg>
    ),
    current: false
  },

  {
    name: 'Manage Stock',
    href: 'manage-products',
    icon: ScaleIcon,
    current: false
  }
];

const couponsLinks = [
  {
    name: 'Add Coupon',
    href: 'add-coupon',
    icon: () => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='m-1 h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z'
        />
      </svg>
    )
  },
  {
    name: 'Manage Coupons',
    href: 'manage-coupons',
    icon: () => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='m-1 h-6 w-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M9 7.5l3 4.5m0 0l3-4.5M12 12v5.25M15 12H9m6 3H9m12-3a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
    )
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CategoryLinks = [
  { name: 'Add Category', href: 'category-to-add', icon: CogIcon },
  {
    name: 'All Categories',
    href: 'manage-category',
    icon: QuestionMarkCircleIcon
  }
];
const colorsLinks = [
  {
    name: 'Add Color',
    href: 'add-color',
    icon: QuestionMarkCircleIcon
  },
  {
    name: 'All Colors',
    href: 'all-colors',
    icon: QuestionMarkCircleIcon
  }
];

const brandsLinks = [
  {
    name: 'All Brands',
    href: 'all-brands',
    icon: QuestionMarkCircleIcon
  }
];

const AdminDashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className='min-h-full bg-white'>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-40 lg:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-[#5f98b7] pb-4 pt-16'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute right-0 top-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only bg-red-400'>Close sidebar</span>
                      <IoMdClose
                        className='h-6 w-6 text-red-700'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex flex-shrink-0 items-center px-4'></div>
                <nav
                  className='mt-5 flex flex-1 flex-col divide-y divide-cyan-700 overflow-y-auto'
                  aria-label='Sidebar'
                >
                  {/* orders links mobile */}
                  <div className='mt-1 pt-1'>
                    <div className='space-y-1 px-2'>
                      {ordersLinks.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className='mt-8 space-y-1 px-2'>
                    {/*Products  links mobile */}
                    {productsLinks.map(item => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-cyan-800 text-white'
                            : 'text-cyan-100 hover:bg-cyan-600 hover:text-white',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon
                          className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className='mt-6 pt-6'>
                    <div className='space-y-1 px-2'>
                      {couponsLinks.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {/* Categories mobile */}
                  <div className='mt-3 pt-3'>
                    <div className='space-y-1 px-2'>
                      {CategoryLinks.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {/* colors links mobile */}
                  <div className='mt-3 pt-3'>
                    <div className='space-y-1 px-2'>
                      {colorsLinks.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  {/* brands links mobile */}
                  <div className='mt-3 pt-3'>
                    <div className='space-y-1 px-2'>
                      {brandsLinks.map(item => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-cyan-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </nav>
                {/* end of mobile nav */}
              </Dialog.Panel>
            </Transition.Child>
            <div className='w-14 flex-shrink-0' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-grow flex-col overflow-y-auto bg-[#5f98b7] pb-4 pt-10'>
          <nav
            className='mt-5 flex flex-1 flex-col divide-y divide-cyan-700 overflow-y-auto'
            aria-label='Sidebar'
          >
            {/* orders links desktop */}
            <div className='mt-1 pt-1'>
              <div className='space-y-1 px-2'>
                {ordersLinks.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className='mt-8 space-y-1 px-2'>
              {/*Products  links desktop */}
              {productsLinks.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-cyan-800 text-white'
                      : 'text-cyan-100 hover:bg-cyan-600 hover:text-white',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'
                    aria-hidden='true'
                  />
                  {item.name}
                </Link>
              ))}
            </div>
            <div className='mt-6 pt-6'>
              <div className='space-y-1 px-2'>
                {couponsLinks.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* Categories desktop */}
            <div className='mt-3 pt-3'>
              <div className='space-y-1 px-2'>
                {CategoryLinks.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* colors links desktop */}
            <div className='mt-3 pt-3'>
              <div className='space-y-1 px-2'>
                {colorsLinks.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            {/* brands links desktop */}
            <div className='mt-3 pt-3'>
              <div className='space-y-1 px-2'>
                {brandsLinks.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-cyan-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className='flex flex-1 flex-col lg:pl-64'>
        <div className='flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none'>
          <button
            type='button'
            className='border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only bg-red-700'>Open sidebar</span>
            <HiMiniBars3 className='h-6 w-6 text-red-700' aria-hidden='true' />
          </button>
        </div>
        <main className='flex-1 pb-8'>
          {/* Page header */}
          <div className='bg-white shadow'>
            <div className='px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8'>
              <div className='py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200'>
                <div className='min-w-0 flex-1'>
                  {/* Profile */}
                  <div className='flex items-center'>
                    <img
                      className='hidden h-16 w-16 rounded-full sm:block'
                      src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80'
                      alt=''
                    />
                    <div>
                      <div className='flex items-center'>
                        <img
                          className='h-16 w-16 rounded-full sm:hidden'
                          src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80'
                          alt=''
                        />
                        <h1 className='ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9'>
                          Good morning, Admin
                        </h1>
                      </div>
                      <dl className='mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                        <dd className='flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6'>
                          {/* Role */}
                          <svg
                            class='h-6 w-6'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              d='M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z'
                            ></path>
                          </svg>
                          Role: Admin
                        </dd>
                        {/* Date Joined */}
                        <dd className='mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0'>
                          <svg
                            className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
                            ></path>
                          </svg>
                          Date Joined: 12/12/2020
                        </dd>
                        {/* email */}
                        <dd className='mt-3 flex items-center text-sm font-medium text-gray-500 sm:mr-6 sm:mt-0'>
                          <svg
                            className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              d='M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207'
                            ></path>
                          </svg>
                          admin@nxxtstore.com
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                {/* <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      Add money
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      Send money
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
          <Outlet />
          {/* content */}
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
