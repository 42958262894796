import { ReactLenis } from 'lenis/dist/lenis-react';
import {
  motion,
  useMotionTemplate,
  useScroll,
  useTransform
} from 'framer-motion';
import { SiSpacex } from 'react-icons/si';
import { FiArrowRight, FiMapPin } from 'react-icons/fi';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

export const SmoothScrollHero = () => {
  return (
    // <div className='bg-zinc-950'>
    <div className='hidden bg-black md:block'>
      <ReactLenis
        root
        options={{
          // Learn more -> https://github.com/darkroomengineering/lenis?tab=readme-ov-file#instance-settings
          lerp: 0.05
          //   infinite: true,
          //   syncTouch: true,
        }}
      >
        <Nav />
        <Hero />
        {/* <Schedule /> */}
      </ReactLenis>
    </div>
  );
};

const Nav = () => {
  return (
    <nav className='fixed left-0 right-0 top-0 z-50 flex items-center justify-between px-6 py-3 text-white'>
      <SiSpacex className='text-3xl mix-blend-difference' />
      <button
        onClick={() => {
          document.getElementById('launch-schedule')?.scrollIntoView({
            behavior: 'smooth'
          });
        }}
        className='flex items-center gap-1 text-xs text-zinc-400'
      >
        LAUNCH SCHEDULE <FiArrowRight />
      </button>
    </nav>
  );
};

// const SECTION_HEIGHT = 1500;
const SECTION_HEIGHT = 1300;

const Hero = () => {
  return (
    <div
      style={{ height: `calc(${SECTION_HEIGHT}px + 100vh)` }}
      className='relative w-full'
    >
      <CenterImage />

      <ParallaxImages />

      {/* <div className='absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-zinc-950/0 to-zinc-950' /> */}
    </div>
  );
};

const CenterImage = () => {
  const { scrollY } = useScroll();

  const clip1 = useTransform(scrollY, [0, 1500], [25, 0]);
  const clip2 = useTransform(scrollY, [0, 1500], [75, 100]);

  const clipPath = useMotionTemplate`polygon(${clip1}% ${clip1}%, ${clip2}% ${clip1}%, ${clip2}% ${clip2}%, ${clip1}% ${clip2}%)`;

  const backgroundSize = useTransform(
    scrollY,
    [0, SECTION_HEIGHT + 500],
    ['170%', '100%']
  );
  const opacity = useTransform(
    scrollY,
    [SECTION_HEIGHT, SECTION_HEIGHT + 500],
    [1, 0]
  );

  return (
    <>
      <motion.div
        className='sticky top-0 h-screen w-full'
        style={{
          clipPath,
          backgroundSize,
          opacity,
          backgroundImage:
            'url(https://res.cloudinary.com/dcinefb4x/image/upload/v1725819026/scroll-hero-rev1_zaxkct.png)',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      />
      {/* <h1 className='sticky top-[140px] text-center text-7xl text-black'>
        What we offer
      </h1> */}
    </>
  );
};

const ParallaxImages = () => {
  return (
    <div className='mx-auto max-w-6xl px-4 pt-[200px]'>
      <Link to='/all-products'>
        <ParallaxImg
          src='https://res.cloudinary.com/dcinefb4x/image/upload/v1725994917/marcus_aureluis_with_name_bp3t9e.png'
          alt='Marcus Aurelius'
          start={-200}
          end={200}
          className='w-1/4'
        />
      </Link>
      {/* <Link to='/all-categories'> */}
      <ParallaxImg
        src='https://res.cloudinary.com/dcinefb4x/image/upload/v1723075845/Mens_Box_rev1_z3th7u.png'
        alt='An example of a space launch'
        start={200}
        end={-250}
        className='mx-auto w-2/3'
      />
      {/* </Link> */}

      <ParallaxImg
        src='https://res.cloudinary.com/dcinefb4x/image/upload/v1726076483/seneca_with_name_bzjaqn.png'
        alt='Orbiting satellite'
        start={-200}
        end={80}
        className='ml-auto w-1/3'
      />
      <ParallaxImg
        src='https://images.unsplash.com/photo-1494022299300-899b96e49893?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        alt='Orbiting satellite'
        start={0}
        end={-500}
        className='ml-24 w-5/12'
      />
    </div>
  );
};

const ParallaxImg = ({ className, alt, src, start, end }) => {
  const ref = useRef(null);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [`${start}px end`, `end ${end * -1}px`]
  });

  const opacity = useTransform(scrollYProgress, [0.75, 1], [1, 0]);
  const scale = useTransform(scrollYProgress, [0.75, 1], [1, 0.85]);

  const y = useTransform(scrollYProgress, [0, 1], [start, end]);
  const transform = useMotionTemplate`translateY(${y}px) scale(${scale})`;

  return (
    // <Link to='/all-products'>
    <motion.img
      src={src}
      alt={alt}
      className={className}
      ref={ref}
      style={{ transform, opacity }}
    />
    // </Link>
  );
};

const Schedule = () => {
  return (
    <section
      id='launch-schedule'
      className='mx-auto max-w-5xl px-4 py-48 text-white'
    >
      <motion.h1
        initial={{ y: 48, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ ease: 'easeInOut', duration: 0.75 }}
        className='mb-20 text-4xl font-black uppercase text-zinc-50'
      >
        Launch Schedule
      </motion.h1>
      <ScheduleItem title='NG-21' date='Dec 9th' location='Florida' />
      <ScheduleItem title='Starlink' date='Dec 20th' location='Texas' />
      <ScheduleItem title='Starlink' date='Jan 13th' location='Florida' />
      <ScheduleItem title='Turksat 6A' date='Feb 22nd' location='Florida' />
      <ScheduleItem title='NROL-186' date='Mar 1st' location='California' />
      <ScheduleItem title='GOES-U' date='Mar 8th' location='California' />
      <ScheduleItem title='ASTRA 1P' date='Apr 8th' location='Texas' />
    </section>
  );
};

const ScheduleItem = ({ title, date, location }) => {
  return (
    <motion.div
      initial={{ y: 48, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.75 }}
      className='mb-9 flex items-center justify-between border-b border-zinc-800 px-3 pb-9'
    >
      <div>
        <p className='mb-1.5 text-xl text-zinc-50'>{title}</p>
        <p className='text-sm uppercase text-zinc-500'>{date}</p>
      </div>
      <div className='flex items-center gap-1.5 text-end text-sm uppercase text-zinc-500'>
        <p>{location}</p>
        <FiMapPin />
      </div>
    </motion.div>
  );
};
