import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
  return (
    <div className='mb-8 h-[340px] w-full lg:w-[96%]'>
      <Link to={`/product/${product.id}`}>
        <img
          src={product?.images[0]}
          className='h-[80%] w-full border border-slate-950 object-cover shadow-sm shadow-slate-900'
          alt=''
        />
        <div className='sec_text flex h-[20%] w-full flex-col text-center'>
          <div className='mt-2 text-2xl font-light'>{product?.name}</div>
          <div className='pri_text'>${product?.price}</div>
          {/* <div className='mt-2'>
            <span>Starting At</span>{' '}
            <span className='text-slate-900'>${product.price}</span>
          </div> */}
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
