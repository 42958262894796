import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// import CatCard from "../categories/CatCard.js";

import './slide.css';

const Slide = ({
  children,
  slidesToSlide,
  removeArrowOnDeviceType,
  partialVisibilityGutter
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1180 },
      // items: 4,
      // slidesToSlide: 4,
      // partialVisibilityGutter: 40,
      items: slidesToSlide,
      slidesToSlide: slidesToSlide,
      partialVisibilityGutter: partialVisibilityGutter
    },
    laptop: {
      breakpoint: { max: 1400, min: 1024 },
      items: 4,
      slidesToSlide: 2,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1124, min: 830 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 30
    },
    miniTablet: {
      breakpoint: { max: 830, min: 730 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 100
    },
    sideMobile: {
      breakpoint: { max: 730, min: 604 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 50
    },
    mobile: {
      breakpoint: { max: 604, min: 536 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 30
    },
    smallMobile: {
      breakpoint: { max: 536, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 200
    },
    xSmallMobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 120
    }
  };

  return (
    <div className='-mt-[500px] w-full pb-20 pt-10 medium:-mt-[80px] md:-mt-[340px] lrg:mt-[0px] xl:mt-[60px]'>
      <div className='slide w-full text-center'>
        {/* <div className='absolute bottom-0 left-0 right-0 h-96 bg-gradient-to-b from-black/0 to-[#a7977e10]' /> */}
        <h1 className='sec_text autoShow mb-12 text-6xl sm:text-7xl'>
          <span className='font-thin'>TRENDING</span> PRODUCTS
        </h1>
        <div className='slide_container'>
          <Carousel
            swipeable={
              responsive !== 'tablet' ||
              'mobile' ||
              'miniTablet' ||
              'smallMobile'
                ? true
                : false
            }
            draggable={
              responsive !== 'tablet' ||
              'mobile' ||
              'miniTablet' ||
              'smallMobile'
                ? true
                : false
            }
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            // removeArrowOnDeviceType={["mobile"]}
            deviceType={responsive.deviceType}
            itemClass='carousel-item'
            partialVisible={true}
          >
            {children}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Slide;
