import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ErrorMsg from '../../shared/ErrorMsg';
import LoadingComponent from '../../shared/LoadingComponent';
import { registerUserAction } from '../../../store/slices/userSlice';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  // select store data
  const { user, error, loading } = useSelector(state => state?.users);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });

  const { name, email, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeHandler = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    dispatch(registerUserAction({ name, email, password }));
    navigate('/login');
  };

  //redirect
  // useEffect(() => {
  //   if (user?.name) {
  //     window.location.href = '/login';
  //   }
  // }, [user]);

  return (
    <>
      <section className='relative mt-[0px] overflow-x-hidden'>
        <div className='container mx-auto w-full max-w-[360px] justify-center rounded-lg bg-black px-4 md:max-w-[600px]'>
          <div className='flex flex-wrap items-center'>
            <div className='mb-2 w-full px-4'>
              <div className='w-full py-20 text-center'>
                <h3 className='font-heading mb-12 text-4xl font-thin text-white md:text-5xl'>
                  sign up
                </h3>
                {/* errr */}
                {error && <ErrorMsg error={error} />}
                <p className='mb-10'>Please, do not hesitate</p>
                <form onSubmit={onSubmitHandler}>
                  <input
                    name='name'
                    value={name}
                    onChange={onChangeHandler}
                    className='mb-4 w-full rounded-md border border-gray-200 px-12 py-6 focus:border-blue-300 focus:ring-blue-300'
                    type='text'
                    placeholder='Name'
                  />
                  <input
                    name='email'
                    value={email}
                    onChange={onChangeHandler}
                    className='mb-4 w-full rounded-md border border-gray-200 px-12 py-6 focus:border-blue-300 focus:ring-blue-300'
                    type='email'
                    placeholder='Enter your email'
                  />
                  <input
                    name='password'
                    value={password}
                    onChange={onChangeHandler}
                    className='mb-4 w-full rounded-md border border-gray-200 px-12 py-6 focus:border-blue-300 focus:ring-blue-300'
                    type='password'
                    placeholder='Enter your password'
                  />
                  {loading ? (
                    <LoadingComponent />
                  ) : (
                    <button className='font-heading mt-4 rounded-md bg-[#a7977e] px-8 py-4 font-bold uppercase text-white hover:border hover:bg-black hover:text-white'>
                      Register
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className='bottom-0 right-0 top-0 hidden bg-cover bg-center bg-no-repeat lg:absolute lg:block lg:w-3/6'
          style={{
            backgroundImage:
              'url("https://cdn.pixabay.com/photo/2017/03/29/04/47/high-heels-2184095_1280.jpg")'
          }}
        /> */}
      </section>
    </>
  );
};

export default RegisterForm;
