import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorComponent from '../../shared/ErrorMsg';
import SuccessMsg from '../../shared/SuccessMsg';
import LoadingComponent from '../../shared/LoadingComponent';
import { createCategoryAction } from '../../../store/slices/categories/categoriesSlice';
import { toast } from 'react-toastify';

export default function AddCategory() {
  const { loading, error, isAdded } = useSelector(state => state?.categories);
  const [formData, setFormData] = useState({
    name: ''
  });
  const [file, setFile] = useState(null);
  const [fileErr, setFileErr] = useState(null);

  const dispatch = useDispatch();

  //---onChange---
  const handleOnChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //file handlechange
  const fileHandleChange = event => {
    const newFile = event.target.files[0];
    //validation
    if (newFile?.size > 10000000) {
      setFileErr(`${newFile?.name} is too large`);
    }
    if (!newFile?.type?.startsWith('image/')) {
      setFileErr(`${newFile?.name} is not an image`);
    }

    setFile(newFile);
  };
  //onSubmit
  const handleOnSubmit = e => {
    console.log(file);
    e.preventDefault();
    //dispatch
    dispatch(
      createCategoryAction({
        name: formData?.name,
        file
      })
    );
    if (isAdded) {
      toast.success('Category added successfully');
    }
  };

  return (
    <>
      {error && <ErrorComponent error={error?.message} />}
      {fileErr && <ErrorComponent error={fileErr} />}
      {/* {isAdded && <SuccessMsg message='Category added successfully' />} */}
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <svg
            className='mx-auto h-10 w-auto text-[#5f98b7]'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='1.5'
            stroke='currentColor'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125'
            />
          </svg>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Add Product Category
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleOnSubmit}>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    onChange={handleOnChange}
                    value={formData.name}
                    name='name'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              {/* upload image */}
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <div className='mt-1 sm:col-span-2 sm:mt-0'>
                  <div className='flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5'>
                    <div className='space-y-1 text-center'>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='flex text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer rounded-md bg-white font-medium text-[#5f98b7] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#5f98b7] focus-within:ring-offset-2 hover:text-[#5f98b7]'
                        >
                          <span>Upload file</span>
                          <input onChange={fileHandleChange} type='file' />
                        </label>
                      </div>
                      <p className='text-xs text-gray-500'>
                        PNG, JPG, GIF up to 1MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    type='submit'
                    className='group relative flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Add Category
                  </button>
                )}
              </div>
            </form>

            <div className='mt-6'>
              <div className='relative'>
                <div className='absolute inset-0 flex items-center'>
                  <div className='w-full border-t border-gray-300' />
                </div>
                <div className='relative flex justify-center text-sm'>
                  <span className='bg-white px-2 text-gray-500'>Or</span>
                </div>
              </div>

              <div className='mt-6 grid grid-cols-3 gap-3'>
                <div>
                  <Link
                    to='/admin/add-brand'
                    className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                  >
                    Add Brand
                  </Link>
                </div>

                <div>
                  <div>
                    <Link
                      to='/admin/add-color'
                      className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                    >
                      Add Color
                    </Link>
                  </div>
                </div>

                <div>
                  <div>
                    <Link
                      to='/admin/add-category'
                      className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
