import Layout from '../components/Layout/Layout';
import ShoppingCart from '../components/ShoppingCart';

const ShoppingCartPage = () => {
  return (
    <Layout>
      <ShoppingCart />
    </Layout>
  );
};

export default ShoppingCartPage;
