import { useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../Layout/Layout';
import { fetchProductsAction } from '../../store/slices/productSlice';
import baseURL from '../../utils/baseURL';
import ProductCard from './product-card/ProductCard';
import { Link } from 'react-router-dom';

const AllProducts = () => {
  const {
    products: { products },
    loading,
    error
  } = useSelector(state => state.products);

  const dispatch = useDispatch();

  let productUrl = `${baseURL}/products/getAllProducts`;

  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl
      })
    );
  }, [dispatch]);

  return (
    <div className='mx-auto mt-10 grid max-w-[1200px] pb-20 md:grid-cols-2 lg:grid-cols-4'>
      {products?.map(product => (
        <Link to={`/product/${product._id}`} key={product._id}>
          <ProductCard key={product?._id} product={product} />
        </Link>
      ))}
    </div>
  );
};

export default AllProducts;
