import { Link } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'Basic Tee',
    href: '#',
    price: '$36.00',
    color: 'Charcoal',
    size: 'L',
    imageSrc:
      'https://tailwindui.com/img/ecommerce-images/confirmation-page-06-product-01.jpg',
    imageAlt: "Model wearing men's charcoal basic tee in large."
  }
  // More products...
];

export default function ThanksForOrdering() {
  return (
    <>
      <main className='relative lg:min-h-full'>
        <div className='h-80 overflow-hidden lg:absolute lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12'>
          <img
            src='https://res.cloudinary.com/dcinefb4x/image/upload/v1721067227/Glassware_vidbac.png'
            alt='TODO'
            className='h-full w-full object-cover object-center'
          />
        </div>

        <div>
          <div className='mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24'>
            <div className='lg:col-start-2'>
              <h1 className='text-sm font-medium text-[#5f98b7]'>
                Payment successful
              </h1>
              <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                Thanks for ordering
              </p>
              <p className='mt-2 text-base text-gray-500'>
                We appreciate your order, we’re currently processing it. So hang
                tight and we’ll send you confirmation very soon!
              </p>

              <div className='mt-16 border-t border-gray-200 py-6 text-right'>
                <Link
                  to='/'
                  className='text-sm font-medium text-[#5f98b7] hover:text-[#5f98b7]'
                >
                  Continue Shopping
                  <span aria-hidden='true'> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
