import React from 'react';
import Layout from '../components/Layout/Layout';
import ThanksForOrdering from '../components/ThanksForShopping';

const SuccessPage = () => {
  return (
    <Layout>
      <ThanksForOrdering />
    </Layout>
  );
};

export default SuccessPage;
