import React from 'react';
import Layout from '../components/Layout/Layout';
import AdminDashboard from '../components/admin/AdminDashboard';
import Header from '../components/Layout/Header';

const AdminPage = () => {
  return (
    <>
      <Header page='admin' />
      <AdminDashboard />
    </>
  );
};

export default AdminPage;
