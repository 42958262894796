import React from 'react';
import imgOne from '../../assets/images/slider_img_1.png';

import './slider.css';

const Slider = () => {
  return (
    <div className='h-22 flex w-full justify-center bg-black pb-10'>
      <div className='slider relative overflow-hidden'>
        <ul>
          <li>
            <img
              src={imgOne}
              alt=''
              className='h-full'
              style={{ position: 'var(--position)' }}
            />
          </li>
          <li>
            <img
              src={imgOne}
              alt=''
              className='h-full'
              style={{ position: 'var(--position)' }}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Slider;
