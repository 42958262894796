import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeroImage from '../assets/images/hero_bg_rev5.png';
import HeroImageT from '../assets/images/hero_bg_rev4.5.png';

const Hero = () => {
  return (
    <div className='mt-24 w-full xl:mt-10'>
      <div className='relative mx-auto flex h-[90vh] w-full max-w-[1800px] font-bold md:h-[60vh] lg:h-[70vh] lg:min-h-[640px] large:h-[80vh]'>
        <h1 className='sec_text z-20 hidden h-[280px] w-full justify-center text-[5rem] sm:text-[6rem] md:flex md:text-[8.8rem] lg:mt-0 lg:text-[11rem] large:text-[14rem] xl:text-[15rem] xxl:text-[16rem]'>
          FOUND
          <span className='font-outline-2 text-black'>URS</span>
        </h1>
        <img
          src={HeroImageT}
          alt='Founders'
          className='absolute left-[0%] top-[20px] z-30 h-[440px] w-full md:left-[31%] md:top-[120px] md:block md:h-[100%] md:max-h-[480px] md:w-[400px] lg:left-[25%] lg:top-[140px] lg:w-[560px] large:left-[30%] large:top-[160px] xl:left-[35%] xl:top-[220px] xl:w-[480px] xxl:left-[35%] xxl:w-[560px]'
        />
        <img
          src={HeroImage}
          alt='Founders'
          className='absolute left-[0%] top-[20px] h-[440px] w-full md:left-[31%] md:top-[120px] md:block md:h-[100%] md:max-h-[480px] md:w-[400px] lg:left-[25%] lg:top-[140px] lg:w-[560px] large:left-[30%] large:top-[160px] xl:left-[35%] xl:top-[220px] xl:w-[480px] xxl:left-[35%] xxl:w-[560px]'
        />

        {/* Header-2 */}
        <div className='pri_text absolute left-1 top-[60px] hidden text-4xl font-thin lg:left-2 lg:top-[280px] xl:left-[86px] xl:top-[320px] xl:block xl:text-8xl xxl:left-[200px]'>
          <div className='relative h-[360px]'>
            <div className='absolute left-[100px] top-0'>
              <div className='autoBlurLoading'>
                <span>F</span>
                <span>e</span>
                <span>a</span>
                <span>r</span>
                <span>l</span>
                <span>e</span>
                <span>s</span>
                <span>s</span>
                <span>l</span>
                <span>y</span>
                <span>,</span>
              </div>
              {/* Fearlessly,{' '} */}
            </div>
            <div className='absolute left-0 top-[110px]'>
              <div className='autoBlurLoading'>
                <span>F</span>
                <span>o</span>
                <span>c</span>
                <span>u</span>
                <span>s</span>
                <span>e</span>
                <span>d</span>
                <span>,</span>
                {/* Focused, */}
              </div>
            </div>
            <div className='absolute bottom-10 left-[100px]'>
              <div className='autoBlurLoading'>
                <span>F</span>
                <span>o</span>
                <span>r</span>
                <span>w</span>
                <span>a</span>
                <span>r</span>
                <span>d</span>
                {/* Forward */}
              </div>
            </div>
          </div>
        </div>

        {/* Text-Right */}
        <div className='absolute left-0 top-[400px] h-[100px] w-[360px] sml:left-[4%] sm:left-[10%] med:left-[15%] medium:left-[20%] md:left-[3%] md:top-[180px] lrg:left-[8%] lg:top-[230px] large:left-[13%] large:top-[280px] xl:left-[72%] xl:top-[320px] xxl:left-[70%] xxl:top-[320px]'>
          <div className='md:relative'>
            <div className='pri_text mb-8 flex flex-col text-xl font-thin md:absolute md:w-[300px] md:text-[1.1rem] lg:-left-12 xl:w-[400px]'>
              <span className='mt-2 hidden md:block'>
                Inspirational Products & Designs with purpose, reflecting a
                commitment to enduring quality and reminding you that true power
                comes from within."
              </span>
            </div>
            <div className='sec_text absolute ml-5 w-[90%] font-thin sm:text-lg md:left-[139%] md:top-2 md:w-[220px] md:text-[1rem] lg:left-[175%] large:left-[220%] xl:-left-[20%] xl:top-[140px] xl:w-[280px]'>
              <span>
                "Don't be afraid to give up the good, to go for the great."
                <br />
                <span className='float-end flex'>— John D. Rockefeller</span>
              </span>

              {/* CTA */}
              <Link
                to='/all-products'
                className='absolute -bottom-[100px] -right-2 flex w-[340px] justify-between rounded border border-[#dddac7] bg-[#000000] px-6 py-2 md:-bottom-[160px] md:-left-[240%] md:w-[220px] lg:-bottom-[140px] lg:-left-[320%] large:-left-[390%] xl:-bottom-[80px] xl:left-[0%] xl:w-[360px] xxl:-bottom-[100px]'
              >
                <span className='pri_text text-3xl font-thin md:hidden xl:block'>
                  Be Great
                </span>
                <span className='w-12 -rotate-[35deg]'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    stroke='#a7977e'
                    className='ml-2 mt-1 w-8'
                  >
                    {' '}
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3'
                    />
                  </svg>
                </span>

                <span className='pri_text text-3xl font-thin md:text-[1.5rem]'>
                  Shop Now
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
