import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CiSearch } from 'react-icons/ci';
import { Dialog, Popover, Tab, Transition } from '@headlessui/react';
import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { HiOutlineBars3, HiOutlineXMark } from 'react-icons/hi2';
import { CgProfile } from 'react-icons/cg';

import Logo from '../../assets/images/f-me-logo-1.png';
import MobileMenu from '../shared/MobileMenu';
import { fetchCategoriesAction } from '../../store/slices/categories/categoriesSlice';
import { getCartItemsFromLocalStorageAction } from '../../store/slices/cartSlice';
import { logoutAction } from '../../store/slices/userSlice';
import { fetchCouponsAction } from '../../store/slices/couponSlice';

const Header = () => {
  const { cartItems } = useSelector(state => state?.cart);
  const {
    categories: { categories }
  } = useSelector(state => state?.categories);
  const {
    coupons
    // loading: couponLoading,
    // error: couponErr
  } = useSelector(state => state?.coupons);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const categoriesToDisplay = categories?.slice(0, 4);
  // const categoriesToDisplay = [];

  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
  }, [dispatch]);
  //get cart items from local storage
  let cartItemsFromLocalStorage;

  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);

  const user = JSON.parse(localStorage.getItem('userInfo'));

  const isLoggedIn = user?.token ? true : false;

  const logoutHandler = () => {
    dispatch(logoutAction());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(fetchCouponsAction());
  }, [dispatch]);

  //get current coupon
  const currentCoupon = coupons?.coupons?.[coupons?.coupons?.length - 1];

  return (
    <header className='w-full justify-center py-0'>
      <div className='fixed top-0 z-[100000] w-full bg-black'>
        {/* Coupon code */}
        {currentCoupon ? (
          <div className='sec_text flex h-8 w-full justify-center pt-1 text-sm'>
            Use code: {currentCoupon?.code} to get {currentCoupon?.discount}%
            OFF, {currentCoupon?.daysLeft}.
          </div>
        ) : (
          <div></div>
        )}

        <div className='relative mx-auto flex h-[56px] max-w-[1280px] justify-between font-light'>
          <div className='flex items-center'>
            <Link to='/'>
              <img
                src={Logo}
                alt='TR Logo'
                className='hidden h-10 md:ml-12 md:block'
              />
              <div className='absolute left-[30%] top-2 flex text-3xl text-white sm:left-[35%] md:hidden'>
                <span className='flex w-full'>FOUND</span>
                <span className=''>URS</span>
              </div>
            </Link>
            <MobileMenu
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              categoriesToDisplay={categoriesToDisplay}
              isLoggedIn={isLoggedIn}
              logoutHandler={logoutHandler}
            />

            <Link className='ml-12' to='/'>
              <CiSearch color='#a7977e' size={24} />
            </Link>
          </div>
          <nav className='mt-3 hidden justify-center md:flex'>
            <ul className='pri_text ml-10 flex gap-12 pl-10 font-light'>
              {categoriesToDisplay?.length <= 0 ? (
                <>
                  <li>
                    <Link to='/all-products'>All Products</Link>
                  </li>
                  <li>
                    <Link to='/products?category=customized-products'>
                      Customized Products
                    </Link>
                  </li>
                  <li>
                    <Link to='/products?category=baskets'>Baskets</Link>
                  </li>
                  <li>
                    <Link to='/products?category=boxes'>Boxes</Link>
                  </li>
                  <li>
                    <Link to='/products?category=personal'>Personal</Link>
                  </li>
                  <li>
                    <Link to='/products?category=business'>Business</Link>
                  </li>
                </>
              ) : (
                categoriesToDisplay?.map(category => {
                  return (
                    <div key={category._id}>
                      <li>
                        <Link
                          to={`/products-filters?category=${category?.name}`}
                          key={category?._id}
                        >
                          {category?.name.toUpperCase()}
                        </Link>
                      </li>
                    </div>
                  );
                })
              )}
            </ul>
          </nav>

          {!isLoggedIn ? (
            <div className='pri_text flex gap-4 pr-0 font-bold sm:pr-4'>
              <Link to='/login'>
                <span className='mt-3 hidden md:flex'>Sign In</span>
              </Link>
              <Link to='/register'>
                <span className='mt-3 hidden md:flex'>Sign Up</span>
              </Link>
              {/* login shopping cart mobile */}
              <div className='flow-root'>
                <Link
                  to='/shopping-cart'
                  className='group mr-2 mt-2 flex items-center p-2'
                >
                  <AiOutlineShoppingCart
                    color='#a7977e'
                    className='pri_text h-6 w-6 flex-shrink-0 group-hover:text-[#dddac790]'
                    aria-hidden='true'
                  />
                  <span className='ml-2 text-sm font-medium text-[#dddac7] group-hover:text-[#dddac790]'>
                    {cartItemsFromLocalStorage?.length > 0
                      ? cartItemsFromLocalStorage?.length
                      : 0}
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            <div className='mr-1 mt-1 flex gap-2'>
              {/* <MobileMenu
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
                categoriesToDisplay={categoriesToDisplay}
                isLoggedIn={isLoggedIn}
                logoutHandler={logoutHandler}
              /> */}
              <Link
                to={user?.user?.isAdmin ? '/admin' : '/customer-profile'}
                className='mr-2 mt-3 hidden gap-2 md:flex'
              >
                <CgProfile color='#a7977e' size={24} />
                <p className='pri_text'>{user?.user?.name}</p>
              </Link>

              {/* login shopping cart mobile */}
              <div className='flow-root'>
                <Link
                  to='/shopping-cart'
                  className='group -m-2 flex items-center pr-4 pt-5'
                >
                  <AiOutlineShoppingCart
                    color='#a7977e'
                    className='h-6 w-6 flex-shrink-0 text-[#a7977e] group-hover:text-[##a7977e90]'
                    aria-hidden='true'
                  />
                  <span className='ml-2 text-sm font-medium text-[#dddac7] group-hover:text-[#dddac790]'>
                    {cartItems?.length > 0 ? cartItems?.length : 0}
                  </span>
                </Link>
              </div>
              {/* Logout User */}
              <button onClick={logoutHandler} className='mt-3 hidden md:flex'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='mb-8 size-6 text-[#a7977e]'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9'
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        categoriesToDisplay={categoriesToDisplay}
        isLoggedIn={isLoggedIn}
      /> */}
    </header>
  );
};

export default Header;
