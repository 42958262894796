import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ErrorComponent from '../../shared/ErrorMsg';
import LoadingComponent from '../../shared/LoadingComponent';
import {
  fetchCategoryAction,
  updateCategoryAction
} from '../../../store/slices/categories/categoriesSlice';
import { toast } from 'react-toastify';

export default function UpdateCategory() {
  const { loading, error, isUpdated, categories } = useSelector(
    state => state.categories
  );

  const { id } = useParams();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: categories?.categories?.name
  });

  //---onChange---
  const handleOnChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //onSubmit
  const handleOnSubmit = e => {
    e.preventDefault();
    dispatch(
      updateCategoryAction({
        ...formData,
        name: formData?.name,
        id
      })
    );
    if (isUpdated) {
      toast.success(categories?.categories?.message);
    }
  };

  return (
    <>
      {error && <ErrorComponent error={error} />}
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <svg
            className='mx-auto h-10 w-auto text-[#5f98b7]'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke-width='1.5'
            stroke='currentColor'
          >
            <path
              stroke-linecap='round'
              stroke-linejoin='round'
              d='M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125'
            />
          </svg>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Update Product Category
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleOnSubmit}>
              <div>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700'
                >
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    onChange={handleOnChange}
                    value={formData.name}
                    name='name'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    type='submit'
                    className='group relative flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Update Category
                  </button>
                )}
              </div>
            </form>

            <div className='mt-6'>
              <div className='relative'>
                <div className='absolute inset-0 flex items-center'>
                  <div className='w-full border-t border-gray-300' />
                </div>
                <div className='relative flex justify-center text-sm'>
                  <span className='bg-white px-2 text-gray-500'>Or</span>
                </div>
              </div>

              <div className='mt-6 grid grid-cols-3 gap-3'>
                <div>
                  <Link
                    to='/admin/add-brand'
                    className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                  >
                    Add Brand
                  </Link>
                </div>

                <div>
                  <div>
                    <Link
                      to='/admin/add-color'
                      className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                    >
                      Add Color
                    </Link>
                  </div>
                </div>

                <div>
                  <div>
                    <Link
                      to='/admin/add-category'
                      className='inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50'
                    >
                      Add Category
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
