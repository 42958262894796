export const categoriesData = [
  {
    id: 1,
    title: 'Slate',
    subTitle: '',
    url: 'slate',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1707662363/andrew-neel-HqtYwlY9dxs-unsplash_dbfn4t.jpg',
    featured: true
  },
  {
    id: 2,
    title: 'Wood',
    subTitle: '',
    url: 'wood',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1723075845/Mens_Box_rev1_z3th7u.png',
    featured: true
  },
  {
    id: 3,
    title: 'Canvas',
    subTitle: '',
    url: 'canvas',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1706193444/testtest/profile_pictures/js3d6kpqqkskdfk6cxxg.jpg',
    featured: true
  },
  {
    id: 4,
    title: 'Access',
    subTitle: '',
    url: 'memorial',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1723244121/TombStone_cn7q7f.png',
    featured: true
  }
];

export const featuredCategoriesData = [
  {
    id: 1,
    title: 'Glassware',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1721067227/Glassware_vidbac.png',
    url: 'slate',
    featured: true
  },
  {
    id: 2,
    title: 'Slate Rock',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1721075445/Slate_Rock_x4c6bx.png',
    url: 'slate',
    featured: true
  },
  {
    id: 3,
    title: 'Kitchenette',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1721333763/Kicthenette_wcqyqh.png',
    url: 'kitchenette',
    featured: true
  },
  {
    id: 4,
    title: 'Wellness',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1706193444/testtest/profile_pictures/js3d6kpqqkskdfk6cxxg.jpg',
    url: 'wellness',
    featured: true
  }
];

export const homeCategoriesData = [
  {
    id: 1,
    title: 'Personal',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1707662432/freestocks-VFrcRtEQKL8-unsplash_haphwj.jpg',
    featured: true
  },
  {
    id: 2,
    title: 'Business',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1707662363/andrew-neel-HqtYwlY9dxs-unsplash_dbfn4t.jpg',
    featured: true
  },
  {
    id: 3,
    title: 'Baskets',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1706195249/testtest/profile_pictures/avjbogg1obb7yqu0dv3n.jpg',
    featured: true
  },
  {
    id: 4,
    title: 'Boxes',
    subTitle: '',
    image_Url:
      'https://res.cloudinary.com/dcinefb4x/image/upload/v1706193444/testtest/profile_pictures/js3d6kpqqkskdfk6cxxg.jpg',
    featured: true
  }
];
