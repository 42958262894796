import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfileAction } from '../../store/slices/userSlice';
import AdminOnly from '../shared/AdminOnly';

const AdminRoutes = ({ children }) => {
  const { userAuth } = useSelector(state => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfileAction());
  }, [dispatch]);

  const isAdmin = userAuth?.userInfo?.user?.isAdmin ? true : false;

  if (!isAdmin) return <AdminOnly />;

  return <>{children}</>;
};

export default AdminRoutes;
