import React from 'react';
import OrderPayment from '../components/OrderPayment';
import Layout from '../components/Layout/Layout';

const OrderPaymentPage = () => {
  return (
    <Layout>
      <OrderPayment />
    </Layout>
  );
};

export default OrderPaymentPage;
