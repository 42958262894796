import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store/store';

//store v2
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const store = createStore(
//   rootReducer,
//   applyMiddleware(thunk),
//   composeWithDevTools()
// )

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
