import { Link } from 'react-router-dom';
import logo from '../../assets/images/f-me-logo-1.png';

export default function AdminOnly() {
  return (
    <>
      <div className='flex min-h-full flex-col bg-white pb-12 pt-16'>
        <main className='mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8'>
          <div className='flex flex-shrink-0 justify-center'>
            <a href='/' className='inline-flex'>
              <img className='h-12 w-auto' src={logo} alt='tr logo' />
            </a>
          </div>
          <div className='py-16'>
            <div className='text-center'>
              <p className='text-base font-semibold text-[#5f98b7]'>404</p>
              <h1 className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
                Sorry, operation not permitted.
              </h1>
              <p className='mt-2 text-base text-gray-500'>Admin Only</p>
              <div className='mt-6'>
                <Link
                  to='/login'
                  className='text-base font-medium text-[#5f98b7] hover:text-[#5f98b790]'
                >
                  Go Home
                  <span aria-hidden='true'> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
