import React from 'react';
import testImage from '../assets/images/mideval_ball_rev3.png';
import BarbImage1 from '../assets/images/mideval_barbed_wire_rev2.png';

const SubSection1 = () => {
  return (
    <div className='xl:mt-30 absolute top-[150px] z-[10] mt-20 h-[340px] w-full items-center'>
      <div className='relative mx-auto max-w-[1280px]'>
        <img
          src={testImage}
          alt=''
          className='autoRotate absolute left-[36%] top-0 z-10 h-[300px] w-[300px]'
        />
        <img
          src={BarbImage1}
          alt=''
          className='absolute right-[9%] top-8 h-[200px] w-[500px]'
        />
        <img
          src={BarbImage1}
          alt=''
          className='img-hor-vert absolute left-[5%] top-8 h-[200px] w-[500px]'
        />
      </div>
    </div>
  );
};

export default SubSection1;
