import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import LoadingComponent from '../../shared/LoadingComponent';
import { loginUserAction } from '../../../store/slices/userSlice';
import ErrorMsg from '../../shared/ErrorMsg';

const LoginForm = () => {
  const { error, loading, userInfo } = useSelector(
    state => state?.users?.userAuth
  );

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const dispatch = useDispatch();

  const { email, password } = formData;

  const onChangeHandler = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = e => {
    e.preventDefault(e);
    dispatch(loginUserAction({ email, password }));
    if (userInfo) {
      toast.success('Logged in successfully');
    }
  };

  //redirect -  check why dependency removes from state
  useEffect(() => {
    if (userInfo) {
      window.location.href = '/';
    }
  }, [userInfo]);

  console.log(userInfo);

  return (
    <>
      <section className='mt-0 overflow-x-hidden py-20'>
        <div className='container relative mx-auto max-w-[360px] px-4 md:max-w-[600px]'>
          <div className='absolute inset-0 -ml-4' />
          {/* Inner Wrap */}
          <div className='relative flex flex-wrap justify-center rounded bg-black'>
            <div className='w-full px-4'>
              <div className='mx-auto py-20 md:px-10 lg:max-w-3xl lg:px-20'>
                <h3 className='font-heading mb-8 text-4xl font-thin text-white md:text-5xl'>
                  Login to customer account
                </h3>
                <p className='font-heading mb-10 font-semibold'>
                  Happy to see you again
                </p>
                {/* err */}
                {error && <ErrorMsg error={error} />}
                {/* {error && (
                  <h1 className='flex justify-center rounded border border-red-400 bg-white py-2 text-center text-red-400'>
                    {error.message}
                  </h1>
                )} */}
                <form
                  className='-mx-4 flex flex-wrap'
                  onSubmit={onSubmitHandler}
                >
                  <div className='mb-8 w-full px-4 md:mb-12 md:w-1/2'>
                    <label>
                      <h4 className='font-heading mb-5 font-bold uppercase text-gray-400'>
                        Your Email
                      </h4>
                      <input
                        name='email'
                        value={email}
                        onChange={onChangeHandler}
                        className='w-full rounded-md border border-gray-200 p-5 focus:border-blue-300 focus:ring-blue-300'
                        type='email'
                      />
                    </label>
                  </div>
                  <div className='mb-12 w-full px-4 md:w-1/2'>
                    <label>
                      <h4 className='font-heading mb-5 font-bold uppercase text-gray-400'>
                        Password
                      </h4>
                      <input
                        name='password'
                        value={password}
                        onChange={onChangeHandler}
                        className='w-full rounded-md border border-gray-200 p-5 focus:border-blue-300 focus:ring-blue-300'
                        type='password'
                      />
                    </label>
                  </div>

                  <div className='w-full px-4'>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <button className='font-heading rounded-md bg-[#a7977e] px-8 py-2 font-bold uppercase text-white hover:border hover:bg-white hover:text-[#a7977e]'>
                        {' '}
                        Login
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
            {/* <div
              className='h-128 flex w-full items-center bg-cover bg-no-repeat px-4 pb-20 md:h-auto md:w-2/6 lg:items-end'
              style={{
                backgroundImage:
                  'url("https://cdn.pixabay.com/photo/2017/03/29/04/47/high-heels-2184095_1280.jpg")'
              }}
            ></div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginForm;
