import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingComponent from '../../shared/LoadingComponent';
import ErrorMsg from '../../shared/ErrorMsg';
import { createCouponAction } from '../../../store/slices/couponSlice';
import { toast } from 'react-toastify';

export default function AddCoupon() {
  const { loading, isAdded, error, coupon } = useSelector(
    state => state?.coupons
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    code: '',
    discount: ''
  });

  //---onHandleChange---
  const onHandleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //---onHandleSubmit---
  const onHandleSubmit = e => {
    e.preventDefault();
    dispatch(
      createCouponAction({
        discount: formData?.discount,
        code: formData?.code,
        startDate,
        endDate
      })
    );
    if (isAdded) {
      toast.success(coupon.message);
    }

    //reset form
    setFormData({
      code: '',
      discount: ''
    });
  };

  return (
    <>
      {error && <ErrorMsg error={error} />}
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
          Add New Coupon
        </h2>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
        <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
          <form className='space-y-6' onSubmit={onHandleSubmit}>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                {/* name */}
                Name
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='code'
                  value={formData.code}
                  onChange={onHandleChange}
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                />
              </div>
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                {/* discount */}
                Discount (in %)
              </label>
              <div className='mt-1'>
                <input
                  name='discount'
                  value={formData.discount}
                  onChange={onHandleChange}
                  type='number'
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                />
              </div>
            </div>
            {/* start date */}
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                Start Date
              </label>
              <div className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                />
              </div>
            </div>

            {/* end date */}
            <div>
              <label className='block text-sm font-medium text-gray-700'>
                End Date
              </label>
              <div className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                />
              </div>
            </div>
            <div>
              {loading ? (
                <LoadingComponent />
              ) : (
                <button
                  type='submit'
                  className='flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                >
                  Add Coupon
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
