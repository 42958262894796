import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

import { fetchBrandsAction } from '../../../store/slices/categories/brandsSlice';
import { fetchCategoriesAction } from '../../../store/slices/categories/categoriesSlice';
import { fetchColorsAction } from '../../../store/slices/categories/colorsSlice';
import { createProductAction } from '../../../store/slices/productSlice';

import ErrorMsg from '../../shared/ErrorMsg';
import LoadingComponent from '../../shared/LoadingComponent';
import SuccessMsg from '../../shared/SuccessMsg';

//animated components for react-select
const animatedComponents = makeAnimated();

export default function AddProduct() {
  //files
  const [files, setFiles] = useState([]);
  const [fileErrs, setFileErrs] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //file handle change
  const fileHandleChange = event => {
    const newFiles = Array.from(event.target.files);
    //validation
    const newErrs = [];
    newFiles.forEach(file => {
      if (file?.size > 10000000) {
        newErrs.push(`${file?.name} is too large`);
      }
      if (!file?.type?.startsWith('image/')) {
        newErrs.push(`${file?.name} is not an image`);
      }
    });
    setFiles(newFiles);
    setFileErrs(newErrs);
  };

  //Sizes
  const sizes = ['S', 'M', 'L', 'XL', 'XXL'];
  const [sizeOption, setSizeOption] = useState([]);
  const handleSizeChange = sizes => {
    setSizeOption(sizes);
  };

  //converted sizes
  const sizeOptionsConverted = sizes?.map(size => {
    return {
      value: size,
      label: size
    };
  });

  //categories
  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);
  //select data from store
  const { categories } = useSelector(state => state?.categories?.categories);

  //brands
  useEffect(() => {
    dispatch(fetchBrandsAction());
  }, [dispatch]);
  //select data from store
  const {
    brands: { brands }
  } = useSelector(state => state?.brands);

  //colors
  const [colorsOption, setColorsOption] = useState([]);

  const {
    colors: { colors }
  } = useSelector(state => state?.colors);
  useEffect(() => {
    dispatch(fetchColorsAction());
  }, [dispatch]);

  const handleColorChange = colors => {
    setColorsOption(colors);
  };
  //converted colors
  const colorsConverted = colors?.map(color => {
    return {
      value: color?.name,
      label: color?.name
    };
  });

  //---form data---
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    district: '',
    phone: ''
  });

  //onChange
  const handleOnChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //get product from store
  const { product, isAdded, loading, error } = useSelector(
    state => state?.products
  );

  //onSubmit
  const handleOnSubmit = e => {
    e.preventDefault();
    console.log(fileErrs);
    //dispatch
    dispatch(
      createProductAction({
        ...formData,
        files,
        colors: colorsOption?.map(color => color?.label),
        sizes: sizeOption?.map(size => size?.label)
      })
    );

    toast.success('Product added successfully');

    //reset form data
    setFormData({
      name: '',
      description: '',
      category: '',
      sizes: '',
      brand: '',
      colors: '',
      images: '',
      price: '',
      totalQty: ''
    });

    navigate('/admin');
  };

  return (
    <>
      {error && <ErrorMsg error={error} />}
      {fileErrs?.length > 0 && (
        <ErrorMsg message='file too large or upload an image' />
      )}
      {/* {isAdded && <SuccessMsg message='Product Added Successfully' />} */}
      <div className='flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-900'>
            Create New Product
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            <p className='font-medium text-[#5f98b7] hover:text-[#5f98b7]'>
              Manage Products
            </p>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleOnSubmit}>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Product Name
                </label>
                <div className='mt-1'>
                  <input
                    name='name'
                    value={formData?.name}
                    onChange={handleOnChange}
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              {/* size option */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Size
                </label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name='sizes'
                  options={sizeOptionsConverted}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable={true}
                  isLoading={false}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={item => handleSizeChange(item)}
                />
              </div>
              {/* Select category */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Category
                </label>
                <select
                  name='category'
                  value={formData.category}
                  onChange={handleOnChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  defaultValue='Canada'
                >
                  <option>-- Select Category --</option>
                  {categories?.map(category => (
                    <option key={category?._id} value={category?.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* Select Brand */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Brand
                </label>
                <select
                  name='brand'
                  value={formData.brand}
                  onChange={handleOnChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  defaultValue='Canada'
                >
                  <option>-- Select Brand --</option>
                  {brands?.map(brand => (
                    <option key={brand?._id} value={brand?.name}>
                      {brand.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select Color */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Select Color
                </label>
                <Select
                  components={animatedComponents}
                  isMulti
                  name='colors'
                  options={colorsConverted}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable={true}
                  isLoading={false}
                  isSearchable={true}
                  closeMenuOnSelect={false}
                  onChange={e => handleColorChange(e)}
                />
              </div>

              {/* upload images */}
              <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5'>
                <label
                  htmlFor='cover-photo'
                  className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
                >
                  Upload Images
                </label>
                <div className='mt-1 sm:col-span-2 sm:mt-0'>
                  <div className='flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5'>
                    <div className='space-y-1 text-center'>
                      <svg
                        className='mx-auto h-12 w-12 text-gray-400'
                        stroke='currentColor'
                        fill='none'
                        viewBox='0 0 48 48'
                        aria-hidden='true'
                      >
                        <path
                          d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                      <div className='flex text-sm text-gray-600'>
                        <label
                          htmlFor='file-upload'
                          className='relative cursor-pointer rounded-md bg-white font-medium text-[#5f98b7] focus-within:outline-none focus-within:ring-2 focus-within:ring-[#5f98b7] focus-within:ring-offset-2 hover:text-[#5f98b7]'
                        >
                          <span>Upload files</span>
                          <input
                            multiple
                            onChange={fileHandleChange}
                            type='file'
                          />
                        </label>
                      </div>
                      <p className='text-xs text-gray-500'>
                        PNG, JPG, GIF up to 1MB
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* price */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Price
                </label>
                <div className='mt-1'>
                  <input
                    name='price'
                    value={formData.price}
                    onChange={handleOnChange}
                    type='number'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>

              {/* Quantity */}
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Total Quantity
                </label>
                <div className='mt-1'>
                  <input
                    name='totalQty'
                    value={formData.totalQty}
                    onChange={handleOnChange}
                    type='number'
                    className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#5f98b7] focus:outline-none focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              {/* description */}
              <div>
                <label
                  htmlFor='comment'
                  className='block text-sm font-medium text-gray-700'
                >
                  Add Product Description
                </label>
                <div className='mt-1'>
                  <textarea
                    rows={4}
                    name='description'
                    value={formData.description}
                    onChange={handleOnChange}
                    className='block w-full rounded-md border border-gray-300 shadow-sm focus:border-[#5f98b7] focus:ring-[#5f98b7] sm:text-sm'
                  />
                </div>
              </div>
              <div>
                {loading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    disabled={fileErrs?.length > 0}
                    type='submit'
                    className='flex w-full justify-center rounded-md border border-transparent bg-[#5f98b7] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#5f98b790] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Add Product
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
