import React, { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import './StartupStoics.css';
import SubSection1 from '../SubSection1';
import StoicsImg from '../../assets/images/Stoics.webp';
import FoundersImg from '../../assets/images/Founders.webp';

const StartupStoics = () => {
  const containerRef = useRef(null);
  // useScroll to get the scroll position
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start 1200px', 'end end']
  });

  // Parallax effect starts at 1000px
  const xText = useTransform(scrollYProgress, [0, 1], ['-100vw', '0vw']);
  const xImage = useTransform(scrollYProgress, [0, 1], ['100vw', '0vw']);
  const blur = useTransform(scrollYProgress, [0, 1], ['80px', '0px']);

  return (
    <div className='relative hidden'>
      <SubSection1 />
      <div ref={containerRef} className='autoBlur container'>
        {/* First Section - Startup Founders */}
        <div className='content'>
          <motion.div
            style={{ x: xText, filter: blur }}
            className='image-section grayscale'
          >
            <img alt='Stoic philosophers' src={StoicsImg} />
          </motion.div>

          <motion.div
            style={{ x: xImage, filter: blur }}
            className='image-section grayscale'
          >
            <img src={FoundersImg} alt='Successful startup founders' />
          </motion.div>
        </div>

        {/* Second Section - Stoic Philosophers */}
        <div className='content'>
          <motion.div
            style={{ x: xText, filter: blur }}
            className='text-section'
          >
            <p>
              Popular Stoics, such as Marcus Aurelius, Seneca, and Epictetus,
              are renowned for their wisdom and focus on inner tranquility. They
              taught that true happiness comes from mastering one's reactions to
              external events, emphasizing the importance of virtue,
              self-discipline, and rational thought. Stoics believed in
              accepting life’s hardships with grace and maintaining a sense of
              control over what is within their power. Their timeless teachings
              on resilience, self-awareness, and perspective continue to inspire
              individuals seeking a deeper sense of purpose and mental clarity.
            </p>
          </motion.div>

          <motion.div
            style={{ x: xImage, filter: blur }}
            className='text-section'
          >
            <p>
              Successful startup founders share common traits that drive their
              ventures to success. They possess a clear vision, resilience to
              overcome challenges, and adaptability to pivot when necessary.
              Their ability to embrace failure as a learning opportunity,
              combined with relentless passion and a strong work ethic, helps
              them push through uncertainty. By building strong teams and
              fostering a culture of innovation, they create environments where
              creativity thrives and ideas flourish, driving their startups
              toward sustained growth and impact.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default StartupStoics;
