import { useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../components/Layout/Layout';
// import { products } from '../data/productData';
import { fetchProductsAction } from '../store/slices/productSlice';
import baseURL from '../utils/baseURL';
import ProductCard from '../components/product/product-card/ProductCard';
import { Link } from 'react-router-dom';
import AllProducts from '../components/product/AllProducts';

const AllProductsPage = () => {
  const {
    products: { products },
    loading,
    error
  } = useSelector(state => state.products);

  const dispatch = useDispatch();

  let productUrl = `${baseURL}/products/getAllProducts`;

  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl
      })
    );
  }, [dispatch]);

  return (
    <Layout page='nothing'>
      <AllProducts />
      {/* <div className='mx-auto my-10 grid max-w-[1200px] bg-black md:grid-cols-2 lg:grid-cols-4'>
        {products?.map(product => (
          <Link to={`/product/${product._id}`} key={product._id}>
            <ProductCard key={product?._id} product={product} />
          </Link>
        ))}
      </div> */}
    </Layout>
  );
};

export default AllProductsPage;
