import React from 'react';
import Layout from '../components/Layout/Layout';
import AddReview from '../components/AddReview';

const AddReviewPage = () => {
  return (
    <Layout>
      <AddReview />
    </Layout>
  );
};

export default AddReviewPage;
