import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthRoute from './components/auth-route/AuthRoute';
import AdminRoutes from './components/auth-route/AdminRoutes';
import AdminPage from './pages/AdminPage';

import AddProduct from './components/admin/products/AddProduct';
import ManageStocks from './components/admin/products/ManageStocks';
import ProductUpdate from './components/admin/products/ProductUpdate';

import AddCoupon from './components/admin/coupons/AddCoupon';
import ManageCoupons from './components/admin/coupons/ManageCoupons';
import UpdateCoupon from './components/admin/coupons/UpdateCoupon';

import CategoryToAdd from './components/admin/categories/CategoryToAdd';
import AddCategory from './components/admin/categories/AddCategory';
import ManageCategories from './components/admin/categories/ManageCategories';
import UpdateCategory from './components/admin/categories/UpdateCategory';
import AddBrand from './components/admin/categories/AddBrand';
import BrandsList from './components/admin/categories/BrandsList';
import AddColor from './components/admin/categories/AddColor';
import ColorsList from './components/admin/categories/ColorsList';
// import Customers from './components/Admin/Orders/Customers';

import OrdersList from './components/admin/orders/OrdersList';
import ManageOrders from './components/admin/orders/ManageOrders';
import UpdateOrders from './components/admin/orders/UpdateOrders';

import Customers from './components/admin/orders/Customers';

import Home from './pages/Home';
import AllProductsPage from './pages/AllProductsPage';
import ProductDetailsPage from './pages/ProductDetailsPage';
import CustomizedProducts from './pages/CustomizedProducts';
import Baskets from './pages/Baskets';
import Boxes from './pages/Boxes';
import Personal from './pages/Personal';
import Business from './pages/Business';
import Login from './pages/Login';
import Register from './pages/Register';
import CustomerProfilePage from './pages/CustomerProfilePage';
import AllCategoriesPage from './pages/AllCategoriesPage';
import ProductsFiltersPage from './pages/ProductsFiltersPage';
import ShoppingCartPage from './pages/ShoppingCartPage';
import OrderPaymentPage from './pages/OrderPaymentPage';
import SuccessPage from './pages/SuccessPage';
import AddReviewPage from './pages/AddReviewPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Admin Routes */}
        <Route
          path='admin'
          element={
            <AdminRoutes>
              <AdminPage />
            </AdminRoutes>
          }
        >
          {/* products */}
          <Route
            path=''
            element={
              <AdminRoutes>
                <OrdersList />
              </AdminRoutes>
            }
          />
          <Route
            path='add-product'
            element={
              <AdminRoutes>
                <AddProduct />
              </AdminRoutes>
            }
          />
          <Route
            path='manage-products'
            element={
              <AdminRoutes>
                <ManageStocks />
              </AdminRoutes>
            }
          />
          <Route
            path='products/edit/:id'
            element={
              <AdminRoutes>
                <ProductUpdate />
              </AdminRoutes>
            }
          />

          {/* coupons */}
          <Route
            path='add-coupon'
            element={
              <AdminRoutes>
                <AddCoupon />
              </AdminRoutes>
            }
          />
          <Route path='manage-coupons' element={<ManageCoupons />} />
          <Route
            path='manage-coupon/edit/:code'
            element={
              <AdminRoutes>
                <UpdateCoupon />
              </AdminRoutes>
            }
          />
          {/* Category */}
          <Route
            path='category-to-add'
            element={
              <AdminRoutes>
                <CategoryToAdd />
              </AdminRoutes>
            }
          />
          <Route
            path='add-category'
            element={
              <AdminRoutes>
                <AddCategory />
              </AdminRoutes>
            }
          />
          <Route
            path='manage-category'
            element={
              <AdminRoutes>
                <ManageCategories />
              </AdminRoutes>
            }
          />
          <Route
            path='edit-category/:id'
            element={
              <AdminRoutes>
                <UpdateCategory />
              </AdminRoutes>
            }
          />
          {/* brand category */}
          <Route
            path='add-brand'
            element={
              <AdminRoutes>
                <AddBrand />
              </AdminRoutes>
            }
          />
          <Route path='all-brands' element={<BrandsList />} />
          {/* color category */}
          <Route
            path='add-color'
            element={
              <AdminRoutes>
                <AddColor />
              </AdminRoutes>
            }
          />
          <Route path='all-colors' element={<ColorsList />} />

          {/* Orders */}
          <Route path='manage-orders' element={<ManageOrders />} />
          <Route
            path='orders/:id'
            element={
              <AdminRoutes>
                <UpdateOrders />
              </AdminRoutes>
            }
          />
          <Route
            path='customers'
            element={
              <AdminRoutes>
                <Customers />
              </AdminRoutes>
            }
          />
        </Route>

        {/* Public Routes */}
        <Route path='/' element={<Home />} />
        {/* Products */}
        <Route path='/all-products' element={<AllProductsPage />} />
        <Route path='/product/:id' element={<ProductDetailsPage />} />
        <Route path='/customized-products' element={<CustomizedProducts />} />
        <Route path='/products-filters' element={<ProductsFiltersPage />} />

        <Route path='/all-categories' element={<AllCategoriesPage />} />
        {/* <Route path='/category/:id' element={<ProductDetails />} /> */}
        <Route path='/baskets' element={<Baskets />} />
        <Route path='/boxes' element={<Boxes />} />
        <Route path='/personal' element={<Personal />} />
        <Route path='/business' element={<Business />} />

        {/* shopping cart */}
        <Route path='/shopping-cart' element={<ShoppingCartPage />} />
        <Route
          path='/order-payment'
          element={
            <AuthRoute>
              <OrderPaymentPage />
            </AuthRoute>
          }
        />
        <Route path='/success' element={<SuccessPage />} />
        <Route
          path='/add-review/:id'
          element={
            <AuthRoute>
              <AddReviewPage />
            </AuthRoute>
          }
        />

        {/* User */}
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route
          path='/customer-profile'
          element={
            <AuthRoute>
              <CustomerProfilePage />
            </AuthRoute>
          }
        />
        {/* <Route path='/social-profile' element={<SocialProfile />} /> */}
      </Routes>

      <ToastContainer
        position='bottom-center'
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        newestOnTop={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
    </Router>
  );
}

export default App;
