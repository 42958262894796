import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import Collections from '../components/Collections';
import Slide from '../components/slide/Slide';
import { Bento } from '../components/Bento';
import FAQ from '../components/FAQ';
import FeaturedCategories from '../components/categories/FeaturedCategories';
import HowItWorks from '../components/HowItWorks';
import ProductCard from '../components/product/product-card/ProductCard';
import baseURL from '../utils/baseURL';
import { fetchProductsAction } from '../store/slices/productSlice';
import { fetchCategoriesAction } from '../store/slices/categories/categoriesSlice';
import SubSection1 from '../components/SubSection1';
import Slider from '../components/slider/Slider';
import Products from '../components/product/Products';
import AllProducts from '../components/product/AllProducts';
import StartupStoics from '../components/startup-stoics/StartupStoics';
import { SmoothScrollHero } from '../components/SmoothScrollHero';

{
  /* TODO: */
}

const Home = () => {
  const {
    products: { products },
    loading,
    error
  } = useSelector(state => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesAction());
  }, [dispatch]);

  let productUrl = `${baseURL}/products/getAllProducts`;

  useEffect(() => {
    dispatch(
      fetchProductsAction({
        url: productUrl
      })
    );
  }, [dispatch]);

  return (
    <Layout>
      <div className='-mt-4'>
        <Hero />
        {/* <div className='relative'> */}
        <SmoothScrollHero />
        {/* </div> */}
        <Slide slidesToSlide={4} partialVisibilityGutter={40}>
          {products ? (
            products?.map(product => (
              <Link to={`/product/${product._id}`} key={product._id}>
                <ProductCard product={product} />
              </Link>
            ))
          ) : (
            <div></div>
          )}
        </Slide>
        <FeaturedCategories />
        <Bento />
        <div className='text-center'>
          <h1 className='sec_text autoShow text-6xl sm:text-7xl'>
            <span className='font-thin'>OUR</span> PRODUCTS
          </h1>
          <AllProducts />
        </div>
        <Collections />
        <StartupStoics />
        <Slider />
        {/* <HowItWorks /> */}
        {/* <FAQ /> */}
      </div>
    </Layout>
  );
};

export default Home;
