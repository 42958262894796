import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Collections = () => {
  const [category, setCategory] = useState('');

  useEffect(() => {}, []);

  return (
    <div className='mx-auto my-16 flex w-full max-w-[1180px] flex-col items-center'>
      <h1 className='sec_text autoShow mb-14 text-[3.5rem] sm:text-7xl'>
        COLLECTIONS
      </h1>
      <div className='flex cursor-pointer flex-wrap justify-center gap-4'>
        <div className='collection relative h-[680px] w-[95%] cursor-pointer overflow-hidden md:w-[48%]'>
          <Link to='/baskets' className='item'>
            <img
              src='https://res.cloudinary.com/dcinefb4x/image/upload/v1725994262/marcus-aurelius_iobc2e.png'
              alt='Business'
              className='h-full'
            />
            <div className='absolute bottom-0 left-0 h-[60px] w-full bg-black/[.6] p-2 text-center text-3xl text-white'>
              Marcus Aurelius
            </div>
            <div className='item absolute bottom-0 left-0 right-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-50'></div>
          </Link>
        </div>
        <div className='collection relative h-[680px] w-[95%] cursor-pointer overflow-hidden md:w-[48%]'>
          <Link to='/baskets' className='item'>
            <img
              src='https://res.cloudinary.com/dcinefb4x/image/upload/v1725994276/alexander-the-great_qsnhjw.png'
              alt='Business'
              className='h-full'
            />
            <div className='absolute bottom-0 left-0 h-[60px] w-full bg-black/[.6] p-2 text-center text-3xl text-white'>
              Alexander the Great
            </div>
            <div className='item absolute bottom-0 left-0 right-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-50'></div>
          </Link>
        </div>
        <div className='collection relative h-[680px] w-[95%] cursor-pointer overflow-hidden md:w-[48%]'>
          <Link to='/boxes' className='item'>
            <img
              src='https://res.cloudinary.com/dcinefb4x/image/upload/v1725994290/caesar-augustus_xqjudo.png'
              alt='Business'
              className='h-full'
            />
            <div className='absolute bottom-0 left-0 h-[60px] w-full bg-black/[.6] p-2 text-center text-3xl text-white'>
              Caesar Augustus
            </div>
            <div className='item absolute bottom-0 left-0 right-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-50'></div>
          </Link>
        </div>

        <div className='collection relative h-[680px] w-[95%] cursor-pointer overflow-hidden md:w-[48%]'>
          <Link to='/boxes' className='item'>
            <img
              src='https://res.cloudinary.com/dcinefb4x/image/upload/v1726010996/Seneca_bmitc4.png'
              alt='Business'
              className='h-full'
            />
            <div className='absolute bottom-0 left-0 h-[60px] w-full bg-black/[.6] p-2 text-center text-3xl text-white'>
              Seneca
            </div>
            <div className='item absolute bottom-0 left-0 right-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-50'></div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Collections;
